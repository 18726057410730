<template>
  <div>
    <div class="banner">
      <img src="../../assets/images/product-banner_10.png" alt="" />
    </div>
    <div class="product-nav-wrap">
      <div class="product-nav">
        <a
          v-for="item in navList"
          :key="item.name"
          @click="navHandle(item.url)"
          >{{ item.name }}</a
        >
      </div>
    </div>
    <div class="program-wrap" id="program-wrap">
      <div
        class="program-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        方案概述
      </div>
      <div
        class="program-mes fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
        style="transition-delay: 0.2s"
      >
        中央空调节能解决方案，是通过对系统各个节点加装传感器来收集整个系统的运行数据，并凭借此类数据不断优化算法模型，进而实现对系统进行动态控制和调整，以使整个系统达到一个最佳的运行状态，从而实现20%-40%的节能效果
      </div>
      <div class="program-content">
        <img src="../../assets/images/energySaving.png" alt="" />
        <div class="program-name">方案优势</div>
        <img
          src="../../assets/images/energySaving-arrow_1.png"
          class="energySaving-arrow energySaving-arrow_1"
          alt=""
        />
        <img
          src="../../assets/images/energySaving-arrow_2.png"
          class="energySaving-arrow energySaving-arrow_2"
          alt=""
        />
        <img
          src="../../assets/images/energySaving-arrow_3.png"
          class="energySaving-arrow energySaving-arrow_3"
          alt=""
        />
        <div
          v-for="(item, index) in programList"
          :key="index"
          class="program-item"
          :class="['program-item_' + (index + 1)]"
        >
          <img :src="item.icon" alt="" />
          <div>
            <h3>{{ item.name }}</h3>
            <span v-html="item.mes"></span>
          </div>
        </div>
        <img
          class="program-img program-img_1"
          src="../../assets/images/aiOPS-img_1.png"
          alt=""
        />
        <img
          class="program-img program-img_2"
          src="../../assets/images/aiOPS-img_2.png"
          alt=""
        />
        <img
          class="program-img program-img_3"
          src="../../assets/images/aiOPS-img_3.png"
          alt=""
        />
        <img
          class="program-img program-img_4"
          src="../../assets/images/aiOPS-img_2.png"
          alt=""
        />
      </div>
    </div>
    <div class="architecture-wrap" id="architecture-wrap">
      <div
        class="architecture-title fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
        方案特性
      </div>
      <div class="architecture-content">
        <div class="architecture-top">
          <div v-for="item in architectureTopList" :key="item">{{ item }}</div>
        </div>
        <div class="architecture-center">
          <div class="architecture-left">
            <div
              class="architecture-item"
              v-for="(item, index) in architectureLeftList"
              :key="item.title"
              :class="['architecture-item_' + (index + 1)]"
            >
              <h3>
                {{ item.title }}
                <div :style="{ width: item.width + 'px' }"></div>
              </h3>

              <ul>
                <li v-for="it in item.list" :key="it">{{ it }}</li>
              </ul>
            </div>
          </div>
          <div class="architecture-middle">
            <img src="../../assets/images/energySaving-style-bg.png" alt="" />
            <img
              src="../../assets/images/architecture-style-icon_1.png"
              class="architecture-style-icon architecture-style-icon_1"
              alt=""
            />
            <img
              src="../../assets/images/architecture-style-icon_2.png"
              class="architecture-style-icon architecture-style-icon_2"
              alt=""
            />
            <img
              src="../../assets/images/architecture-style-icon_3.png"
              class="architecture-style-icon architecture-style-icon_3"
              alt=""
            />
            <img
              src="../../assets/images/architecture-style-icon_4.png"
              class="architecture-style-icon architecture-style-icon_4"
              alt=""
            />
            <img
              src="../../assets/images/architecture-style-icon_5.png"
              class="architecture-style-icon architecture-style-icon_5"
              alt=""
            />
            <img
              src="../../assets/images/architecture-style-icon_6.png"
              class="architecture-style-icon architecture-style-icon_6"
              alt=""
            />
          </div>
          <div class="architecture-right">
            <div
              class="architecture-item"
              v-for="(item, index) in architectureRightList"
              :key="item.title"
              :class="['architecture-item_' + (index + 1)]"
            >
              <h3>
                {{ item.title }}
                <div :style="{ width: item.width + 'px' }"></div>
              </h3>
              <ul>
                <li v-for="it in item.list" :key="it">{{ it }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../../utils/index.js";

export default {
  data() {
    return {
      navList: [
        {
          name: "方案概述",
          url: "program-wrap",
        },
        {
          name: "方案特性",
          url: "architecture-wrap",
        },
      ],
      programList: [
        {
          name: "智能控制和顺序控制",
          mes:
            "楼控类公司主要是以PLC/DDC为基础进行简单控制，我们是以智能算<br>法来动态全局控制",
          animate: "animate6",
          delay: 0.1,
          icon: require("../../assets/images/energySaving-icon_1.png"),
        },
        {
          name: "产品和项目",
          mes:
            "我们是产品化部署稳定性安全性<br>高，多数公司是以项目方式做集<br>成，现场调试等，稳定性不高",
          animate: "animate7",
          delay: 0.15,
          icon: require("../../assets/images/energySaving-icon_2.png"),
        },
        {
          name: "结果",
          mes: "我们的节能率最高",
          animate: "animate8",
          delay: 0.2,
          icon: require("../../assets/images/energySaving-icon_3.png"),
        },
        {
          name: "整体和部分",
          mes:
            "我们是对空调/锅炉系统整体进行<br>节能控制，多数公司是针对部分设<br>备进行控制",
          animate: "animate9",
          delay: 0.25,
          icon: require("../../assets/images/energySaving-icon_4.png"),
        },
      ],
      architectureTopList: [
        "采用嵌入式硬件、软件和统一产品开发平台",
        "结合物联网、移动互联和大数据平台技术",
        "采用独创的大数据集群算法+智能寻优控制技术 ",
        "智能动态控制中央空调流量、冷量、热量、风量，实现人工环境设备能效提升",
      ],
      architectureLeftList: [
        {
          title: "良好的系统架构",
          width: 458,
          list: [
            "强弱电、节能一体化设计",
            "易扩展的网络结构",
            "标准的对外接口",
            "全网的远程能源监测",
          ],
        },
        {
          title: "先进而专业的控制算法",
          width: 381,
          list: [
            "两器温差最佳效率群控",
            "冷却水裕度自适应优化算法",
            "冷冻水负荷动态平衡算法",
            "风机智能控制技术",
            "锅炉进出水温差平衡算法",
          ],
        },
        {
          title: "全面的设备能耗计量",
          width: 453,
          list: [
            "制冷机组能耗计量",
            "水泵能耗计量",
            "风机能耗计量",
            "制冷机组冷量计量",
            "锅炉供热量计量",
            "锅炉能耗计量",
          ],
        },
      ],
      architectureRightList: [
        {
          title: "满意的综合效果",
          width: 458,
          list: [
            "良好的节能收益",
            "运行稳定可靠",
            "功能完善、操作简便",
            "易扩展、易维护",
          ],
        },
        {
          title: "完善的系统安全保护",
          width: 381,
          list: ["多级操作权限设置", "多重安全保护措施", "完善的可靠性设计"],
        },
        {
          title: "丰富的数据统计分析",
          width: 450,
          list: [
            "空调系统运行参数统计",
            "制冷机组及系统效率统计",
            "系统操作及故障记录统计",
          ],
        },
      ],
      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  img {
    width: 100%;
    display: block;
  }
}
.program-wrap {
  padding: 50px 0 130px;
  background: #f7f9ff;
  // position: relative;
  .program-title {
    color: #243159;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
  }
  .program-mes {
    color: #63697b;
    font-size: 16px;
    width: 995px;
    line-height: 30px;
    margin: 24px auto 0;
    text-align: center;
  }
  .program-content {
    width: 1180px;
    margin: 44px auto 0;
    position: relative;
    text-align: center;
    padding: 162px 0 90px;
  }
  .program-item {
    position: absolute;
    div {
      position: absolute;
      background: #ffffff;
      box-shadow: 0px 0px 10px 6px rgba(107, 107, 107, 0.05);
      border-radius: 10px;
      padding: 15px 16px;
      white-space: nowrap;
      font-size: 14px;
      color: #63697b;
      line-height: 30px;
      h3 {
        font-size: 18px;
        color: #243159;
        line-height: 24px;
        margin-bottom: 10px;
      }
    }
    &.program-item_1 {
      left: 50%;
      top: 115px;
      transform: translate(-50%, 0);
      img {
        width: 107px;
      }
      div {
        top: -3px;
        left: 50%;
        transform: translate(-50%, -100%);
      }
    }
    &.program-item_2 {
      left: 800px;
      top: 185px;
      img {
        width: 132px;
      }
      div {
        top: 0px;
        left: 100%;
        transform: translate(0%, 0);
      }
    }
    &.program-item_3 {
      left: 50%;
      top: 285px;
      transform: translate(-50%, 0);
      img {
        width: 139px;
      }
      div {
        bottom: -90px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    &.program-item_4 {
      left: 300px;
      top: 195px;
      transform: translate(-50%, 0);
      img {
        width: 133px;
      }
      div {
        bottom: 0;
        left: 0;
        transform: translate(-100%, 0);
      }
    }
  }
  .program-name {
    color: #1d5690;
    font-size: 24px;
    position: absolute;
    font-weight: bold;
    -webkit-text-stroke: 1px #979797;
    text-stroke: 1px #979797;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -25%);
  }
  .energySaving-arrow {
    position: absolute;
    &.energySaving-arrow_1 {
      left: 430px;
      top: 185px;
    }
    &.energySaving-arrow_2 {
      left: 710px;
      top: 185px;
    }
    &.energySaving-arrow_3 {
      left: 700px;
      top: 310px;
    }
  }
  .program-img {
    position: absolute;
    &.program-img_1 {
      width: 147px;
      top: 100px;
      right: 170px;
    }

    &.program-img_2 {
      width: 127px;
      left: 80px;
      bottom: -50px;
    }
    &.program-img_3 {
      width: 85px;
      left: 80px;
      top: 25px;
    }
    &.program-img_4 {
      width: 126px;
      bottom: -10px;
      right: 160px;
    }
  }
}
.architecture-wrap {
  background: #fff;
  padding: 47px 0 68px;
  .architecture-title {
    font-size: 40px;
    line-height: 52px;
    color: #243159;
    text-align: center;
  }
  .architecture-content {
    width: 1180px;
    margin: 47px auto 0;
    text-align: center;
  }
  .architecture-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1180px;
    div {
      width: 279px;
      height: 77px;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #545f7f;
      border-radius: 6px;
      font-size: 16px;
      line-height: 21px;
      color: #fff;
    }
  }
  .architecture-center {
    display: flex;
    justify-content: space-between;
  }
  .architecture-middle {
    width: 342px;
    margin: 0 49px;
    padding-top: 66px;
    position: relative;
    .architecture-style-icon {
      position: absolute;
      &.architecture-style-icon_1 {
        right: 32px;
        top: 38px;
      }
      &.architecture-style-icon_2 {
        right: -45px;
        top: 180px;
      }
      &.architecture-style-icon_3 {
        right: 20px;
        top: 340px;
      }
      &.architecture-style-icon_4 {
        left: 32px;
        top: 38px;
      }
      &.architecture-style-icon_5 {
        left: -45px;
        top: 180px;
      }
      &.architecture-style-icon_6 {
        left: 20px;
        top: 340px;
      }
    }
  }
  .architecture-left,
  .architecture-right {
    padding-top: 43px;
    flex: 1;
    .architecture-item_2 {
      height: 188px;
    }
    h3 {
      font-size: 18px;
      color: #243159;
      line-height: 24px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 18px;
      position: relative;
      div {
        border-bottom: 1px dashed #d2d3d7;
        position: absolute;
        width: 100%;
        bottom: -6px;
        &::before {
          content: "";
          position: absolute;
          width: 27px;
          height: 3px;
          background: #5dc9fb;
          border-radius: 2px;
          top: -1px;
        }
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 20px;
    }
    li {
      width: 180px;
      height: 35px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #bdc5e2;
      font-size: 14px;
      color: #243159;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
    }
  }
  .architecture-right {
    h3 {
      text-align: right;
      div {
        right: 0;
        &::before {
          background: #e56b61;
          right: 0;
        }
      }
    }
  }
}
</style>