<template>
  <div>
    <div class="banner">
      <img src="../../assets/images/product-banner_8.png" alt="" />
    </div>
    <div class="product-nav-wrap">
      <div class="product-nav">
        <a
          v-for="item in navList"
          :key="item.name"
          @click="navHandle(item.url)"
          >{{ item.name }}</a
        >
      </div>
    </div>
    <div class="program-wrap" id="program-wrap">
      <div
        class="program-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        方案概述
      </div>
      <div class="program-content">
        <img src="../../assets/images/fusion-bg.png" alt="" />
        <div
          v-for="(item, index) in programList"
          :key="index"
          class="program-item"
          :class="['program-item_' + (index + 1)]"
        >
          <span class="program-item_num">0{{ index + 1 }}</span>
          <div>{{ item.mes }}</div>
        </div>
        <img
          class="program-img program-img_1"
          src="../../assets/images/aiOPS-img_1.png"
          alt=""
        />
        <img
          class="program-img program-img_2"
          src="../../assets/images/aiOPS-img_2.png"
          alt=""
        />
        <img
          class="program-img program-img_3"
          src="../../assets/images/aiOPS-img_3.png"
          alt=""
        />
        <img
          class="program-img program-img_4"
          src="../../assets/images/aiOPS-img_4.png"
          alt=""
        />
      </div>
    </div>
    <div class="architecture-wrap" id="architecture-wrap">
      <div
        class="architecture-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        系统架构图
      </div>
      <div class="architecture-content">
        <div
          class="architecture-item"
          ref="animate2"
          :class="{ fadeInUp: animateData.animate2 }"
        >
          <div class="architecture-name">“微电网”系统</div>
          <div class="architecture-ul">
            <div>充电站</div>
            <div>光伏站</div>
            <div>供电服务站</div>
            <div>储能站</div>
          </div>
          <img src="../../assets/images/fusion-arrow.png" alt="" />
          <div class="architecture-bottom">多维融合</div>
        </div>
        <div
          class="architecture-item"
          ref="animate3"
          :class="{ fadeInUp: animateData.animate3 }"
          style="transition-delay: 0.05s"
        >
          <div class="architecture-name">智慧用能管理系统</div>
          <div class="architecture-li">智慧用能站</div>
          <img src="../../assets/images/fusion-arrow.png" alt="" />
          <div class="architecture-bottom">多能互补</div>
        </div>
        <div
          class="architecture-item"
          ref="animate4"
          :class="{ fadeInUp: animateData.animate4 }"
          style="transition-delay: 0.1s"
        >
          <div class="architecture-name">智慧应用体系</div>
          <div class="architecture-ul">
            <div>5G基站</div>
            <div>北斗基站</div>
            <div>应急抢修站</div>
            <div>物资仓储站</div>
          </div>
          <img src="../../assets/images/fusion-arrow.png" alt="" />
          <div class="architecture-bottom">智慧应用</div>
        </div>
        <div
          class="architecture-item"
          ref="animate5"
          :class="{ fadeInUp: animateData.animate5 }"
          style="transition-delay: 0.15s"
        >
          <div class="architecture-name">数字新基建</div>
          <div class="architecture-li">数据中心站</div>
          <img src="../../assets/images/fusion-arrow.png" alt="" />
          <div class="architecture-bottom">共建、共享、共赢</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../../utils/index.js";

export default {
  data() {
    return {
      navList: [
        {
          name: "方案概述",
          url: "program-wrap",
        },
        {
          name: "系统架构图",
          url: "architecture-wrap",
        },
      ],
      programList: [
        {
          mes: "以数据中心站为基础，打造“共建、共享、共赢”的数字新基建运营模式",
          animate: "animate6",
          delay: 0.1,
        },
        {
          mes:
            "构筑多维融合的“微电网”系统，实现充电站、光伏站、供电服务站、储能站的融合",
          animate: "animate7",
          delay: 0.15,
        },
        {
          mes:
            "建设智慧用能管理系统，通过智慧用能站来实现“多能互补”",
          animate: "animate8",
          delay: 0.2,
        },
        {
          mes:
            "基于5G基站、北斗基站，打造智慧应用场景，并为应急抢修站、物资仓储站的高效运转提供技术支撑。通过方案的逐步落实，逐步实现“十站融合”，以此推进微电网、智慧能源、数字能源深度开发应用，构建投资界面清晰、运营有序高效、环境开放友好的运营模式",
          animate: "animate9",
          delay: 0.25,
        },
      ],

      animateData: {
        animate: false,
        animate1: false,
      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  img {
    width: 100%;
    display: block;
  }
}
.program-wrap {
  padding: 50px 0 130px;
  background: #f7f9ff;
  // position: relative;
  .program-title {
    color: #243159;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
  }
  .program-content {
    width: 1180px;
    margin: 44px auto 0;
    position: relative;
    text-align: center;
  }
  .program-item {
    position: absolute;
    width: 299px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 6px rgba(107, 107, 107, 0.05);
    border-radius: 10px;
    padding: 26px 18px 23px 9px;
    span {
      font-size: 30px;
      color: #6e7c8a;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 21px;
        height: 1px;
        background: #aab0b1;
        left: -70%;
        top: 110%;
        transform: translate(0) rotate(-40deg);
      }
      &::after {
        content: "";
        position: absolute;
        width: 21px;
        height: 1px;
        background: #aab0b1;
        right: -70%;
        top: -10%;
        transform: translate(0) rotate(-40deg);
      }
    }
    div {
      font-size: 14px;
      color: #777d8f;
      line-height: 30px;
      margin-top: 20px;
    }
    &.program-item_1 {
      top: 6%;
      left: -10px;
    }
    &.program-item_2 {
      right: 0;
      top: 6%;
    }
    &.program-item_3 {
      left: -10px;
      top: 230px;
    }
    &.program-item_4 {
      right: 0;
      top: 230px;
    }
  }
  .program-img {
    position: absolute;
    &.program-img_1 {
      width: 148px;
      top: -55px;
      right: 200px;
    }

    &.program-img_2 {
      width: 127px;
      left: 330px;
      bottom: -90px;
    }
    &.program-img_3 {
      width: 85px;
      left: 320px;
      top: -45px;
    }
    &.program-img_4 {
      bottom: -100px;
      right: 320px;
    }
  }
}
.architecture-wrap {
  background: #fff;
  padding: 47px 0 42px;
  .architecture-title {
    font-size: 40px;
    line-height: 52px;
    color: #243159;
    text-align: center;
  }
  .architecture-content {
    width: 1180px;
    margin: 47px auto 0;
    display: flex;
    text-align: center;
  }
  .architecture-item {
    flex: 1;
    background: #ffffff;
    box-shadow: 0px 0px 10px 8px rgba(90, 90, 90, 0.08);
    border-radius: 8px;
    margin-right: 16px;
    padding-bottom: 26px;
    &:last-child {
      margin-right: 0;
    }
  }
  .architecture-name {
    height: 69px;
    line-height: 69px;
    background: #545f7f;
    border-radius: 8px 8px 0px 0px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
  }
  .architecture-li {
    display: flex;
    width: 238px;
    height: 76px;
    border-radius: 3px;
    border: 1px solid #3d93e6;
    margin: 22px auto 15px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #3d93e6;

    div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #3d93e6;
      width: 115px;
    }
  }
  .architecture-ul {
    display: flex;
    width: 238px;
    height: 76px;
    border-radius: 3px;
    margin: 22px auto 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #3d93e6;
      height: 32px;
      color: #3d93e6;
      width: 115px;
      margin-bottom: 12px;
      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }
  .architecture-bottom {
    width: 238px;
    height: 58px;
    display: flex;
    margin: 13px auto 0;
    justify-content: center;
    align-items: center;
    background: #60b7d6;
    border-radius: 3px;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
  }
}
</style>