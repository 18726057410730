<template>
  <div>
    <div class="banner">
      <img src="../../assets/images/product-banner_7.png" alt="" />
    </div>
    <div class="product-nav-wrap">
      <div class="product-nav">
        <a
          v-for="item in navList"
          :key="item.name"
          @click="navHandle(item.url)"
          >{{ item.name }}</a
        >
      </div>
    </div>
    <div class="program-wrap" id="program-wrap">
      <div
        class="program-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
        style="transition-delay: 0.1s"
      >
        方案概述
      </div>
      <div
        class="program-mes fadeOutUp"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
        style="transition-delay: 0.2s"
      >
        能源大数据应用中心按功能划分为三个子系统平台：大数据业务平台、公共云服务平台、超算平台，通过把资源数据、数值天气预报数据、新能源电站运行数据、电网运行数据、负荷数据、新能源实证测试数据接入电力系统运行全面数据集中处理后，通过建立业务模型和采取复杂的计算服务，为电网企业、政府、发电企业和制造业提供综合服务
      </div>
      <div class="program-content">
        <div class="program-bottom">
          <div class="program-bottom_name">子系统平台</div>
          <div class="program-bottom_item">
            <img src="../../assets/images/program-img_1.png" alt="" />
            <div>超算平台</div>
          </div>
          <div class="program-bottom_item">
            <img src="../../assets/images/program-img_2.png" alt="" />
            <div>公共云服务平台</div>
          </div>
          <div class="program-bottom_item">
            <img src="../../assets/images/program-img_3.png" alt="" />
            <div>大数据业务平台</div>
          </div>
        </div>
        <div
          class="program-item"
          :class="'program-item_' + (index + 1)"
          v-for="(item, index) in programList"
          :key="item.name"
        >
          <div class="program-item-icon">
            <img :src="item.icon" alt="" />
          </div>
          <span class="program-item-name">{{ item.name }}</span>
        </div>
        <img
          class="program-img program-img_1"
          src="../../assets/images/aiOPS-img_1.png"
          alt=""
        />
        <img
          class="program-img program-img_2"
          src="../../assets/images/aiOPS-img_2.png"
          alt=""
        />
        <img
          class="program-img program-img_3"
          src="../../assets/images/aiOPS-img_3.png"
          alt=""
        />
      </div>
    </div>
    <div class="architecture-wrap" id="architecture-wrap">
      <div
        class="architecture-title fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        系统架构图
      </div>
      <div class="architecture-content">
        <div class="architecture-top">
          <div class="architecture-content_name">
            业<br />务<br />应<br />用
          </div>
          <div class="architecture-content_block">
            <div
              class="architecture-content_item"
              v-for="item in architectureTop"
              :key="item.name"
            >
              <div class="architecture-content_title">{{ item.name }}</div>
              <div
                class="architecture-content_li"
                v-for="i in item.list"
                :key="i"
              >
                {{ i }}
              </div>
            </div>
          </div>
        </div>
        <div class="architecture-center">
          <div class="architecture-content_name">子系统平台</div>
          <div class="architecture-content_block">
            <div
              class="architecture-content_item"
              v-for="item in architectureCenter"
              :key="item.name"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="architecture-bottom">
          <div class="architecture-content_name">数据输入</div>
          <div class="architecture-content_block">
            <div
              class="architecture-content_item"
              v-for="item in architectureBottom"
              :key="item.name"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../../utils/index.js";

export default {
  data() {
    return {
      navList: [
        {
          name: "方案概述",
          url: "program-wrap",
        },
        {
          name: "系统架构图",
          url: "architecture-wrap",
        },
      ],
      programList: [
        {
          icon: require("../../assets/images/program-icon_1.png"),
          name: "新能源实证测试数据",
        },
        {
          icon: require("../../assets/images/program-icon_2.png"),
          name: "负荷数据",
        },
        {
          icon: require("../../assets/images/program-icon_3.png"),
          name: "电网运行数据",
        },
        {
          icon: require("../../assets/images/program-icon_4.png"),
          name: "新能源电站运行数据",
        },
        {
          icon: require("../../assets/images/program-icon_5.png"),
          name: "数值天气预报数据",
        },
        {
          icon: require("../../assets/images/program-icon_6.png"),
          name: "资源数据",
        },
      ],
      architectureTop: [
        {
          name: "数据预报",
          list: [
            "发电能力预测",
            "气象灾害预警",
            "新能源电站选址辅助决策",
            "水文预报",
          ],
        },
        {
          name: "数据监测",
          list: [
            "监测数据质量管理",
            "自动告警",
            "多样化数据监测",
            "日常值班管理",
            "明细数据计算汇总",
          ],
        },
        {
          name: "全景展示",
          list: ["主题配置管理", "展示场景管理", "数据状态估计"],
        },
        {
          name: "运营分析",
          list: [
            "分析模型管理",
            "分析业务开展",
            "分析报告管理",
            "明细数据溯源",
          ],
        },
        {
          name: "综合管理",
          list: ["电站运维管理", "发电并网规划", "数据集成管理"],
        },
      ],
      architectureCenter: ["大数据业务平台", "公共云服务平台", "超算平台"],
      architectureBottom: [
        "资源数据",
        "数值天气预报数据",
        "新能源电站运行数据",
        "电网运行数据",
        "负荷数据",
        "新能源实证测试数据",
      ],
      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.animateData.animate = true;
      this.animateData.animate2 = true;
    });
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  img {
    width: 100%;
    display: block;
  }
}
.program-wrap {
  padding: 50px 0 1px;
  background: #f7f9ff;
  .program-title {
    color: #243159;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
  }
  .program-mes {
    color: #63697b;
    font-size: 16px;
    width: 995px;
    line-height: 30px;
    margin: 24px auto 0;
    text-align: center;
  }
  .program-content {
    width: 954px;
    height: 478px;
    margin: 124px auto;
    background: url("../../assets/images/program-bg.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
  }
  .program-bottom {
    position: absolute;
    width: 569px;
    height: 210px;
    background: url("../../assets/images/program-bottom.png");
    left: 50%;
    bottom: -36px;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 45px;
  }
  .program-bottom_name {
    color: #4f5978;
    font-size: 20px;
    position: absolute;
    left: 50%;
    bottom: 69px;
    transform: translate(-50%, 0);
    font-weight: bold;
  }
  .program-bottom_item {
    position: relative;
    bottom: 30px;
    &:nth-child(3) {
      bottom: 110px;
    }
    div {
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translate(-50%, -100%);
      font-size: 16px;
      color: #4f5978;
      font-weight: bold;
      background: #ffffff;
      box-shadow: 0px 2px 9px 4px rgba(110, 138, 225, 0.12);
      border-radius: 4px;
      padding: 10px 12px;
      white-space: nowrap;
      &::before {
        content: "";
        width: 0;
        height: 0;
        border-width: 0 10px 10px;
        border-style: solid;
        border-color: transparent transparent#fff;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0) rotate(180deg);
      }
    }
  }
  .program-item {
    position: absolute;
    .program-item-icon {
      width: 109px;
      height: 109px;
      background: #ffffff;
      border-radius: 50%;
      box-shadow: 0px 2px 6px 3px rgba(107, 107, 107, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .program-item-name {
      position: absolute;
      white-space: nowrap;
      font-size: 14px;
      color: #243159;
      padding: 7px 15px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid #bdc5e2;
    }
    &.program-item_1 {
      bottom: -16px;
      left: 0;
      transform: translate(-50%, 0);
      .program-item-name {
        bottom: -50px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    &.program-item_2 {
      left: 50px;
      bottom: 230px;
      .program-item-name {
        left: -9px;
        top: 40%;
        transform: translate(-100%, -50%);
      }
    }
    &.program-item_3 {
      left: 247px;
      bottom: 392px;
      .program-item-name {
        top: -11px;
        left: 50%;
        transform: translate(-50%, -100%);
      }
    }
    &.program-item_4 {
      left: 606px;
      bottom: 392px;
      .program-item-name {
        top: -11px;
        left: 50%;
        transform: translate(-50%, -100%);
      }
    }
    &.program-item_5 {
      right: 44px;
      bottom: 231px;
      .program-item-name {
        right: -9px;
        top: 40%;
        transform: translate(100%, -50%);
      }
    }
    &.program-item_6 {
      bottom: -16px;
      right: 0;
      transform: translate(50%, 0);
      .program-item-name {
        bottom: -50px;
        right: 50%;
        transform: translate(50%, 0);
      }
    }
  }
  .program-img {
    position: absolute;
    &.program-img_1 {
      width: 148px;
      left: -117px;
      top: 49px;
    }
    &.program-img_2 {
      width: 127px;
      right: -42px;
      top: 49px;
    }
    &.program-img_3 {
      width: 85px;
      right: -42px;
      top: -29px;
      transform: translate(0, -100%);
    }
  }
}
.architecture-wrap {
  background: #fff;
  padding: 44px 0 42px;
  .architecture-title {
    font-size: 40px;
    line-height: 52px;
    color: #243159;
    text-align: center;
  }
  .architecture-content {
    width: 1180px;
    margin: 26px auto 0;
  }
  .architecture-top {
    display: flex;
    background: #fff;
    box-shadow: 0px 0px 10px 8px rgba(90, 90, 90, 0.08);
    border-radius: 8px;
    .architecture-content_name {
      font-size: 24px;
      color: #fff;
      width: 143px;
      background: #545f7f;
      border-radius: 8px 0px 0px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
    .architecture-content_block {
      padding: 14px 14px 26px 23px;
      display: flex;
      justify-content: space-between;
      flex: 1;
    }
    .architecture-content_item {
      background: url("../../assets/images/program-app-bg.png") no-repeat;
      background-size: 100% 100%;
      width: 195px;
      height: 238px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 33px;
    }
    .architecture-content_title {
      font-size: 18px;
      color: #4f5978;
      position: absolute;
      left: 50%;
      top: -6px;
      transform: translate(-50%, 0);
    }
    .architecture-content_li {
      width: 169px;
      height: 32px;
      background: #3d93e6;
      border-radius: 3px;
      margin-bottom: 7px;
      text-align: center;
      line-height: 32px;
      color: #fff;
      font-size: 14px;
    }
  }
  .architecture-center {
    display: flex;
    margin: 19px 0 17px;

    box-shadow: 0px 0px 10px 8px rgba(90, 90, 90, 0.08);
    border-radius: 8px;
    .architecture-content_name {
      width: 143px;
      background: #545f7f;
      border-radius: 8px 0px 0px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #fff;
      font-weight: bold;
    }
    .architecture-content_block {
      flex: 1;
      padding: 15px 27px 16px 35px;
      display: flex;
    }
    .architecture-content_item {
      flex: 1;
      height: 38px;
      text-align: center;
      line-height: 38px;
      background: #60b7d6;
      border-radius: 3px;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .architecture-bottom {
    display: flex;
    margin: 19px 0 17px;

    box-shadow: 0px 0px 10px 8px rgba(90, 90, 90, 0.08);
    border-radius: 8px;
    .architecture-content_name {
      width: 143px;
      background: #545f7f;
      border-radius: 8px 0px 0px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #fff;
      font-weight: bold;
    }
    .architecture-content_block {
      flex: 1;
      padding: 15px 27px 16px 35px;
      display: flex;
    }
    .architecture-content_item {
      flex: 1;
      height: 38px;
      text-align: center;
      line-height: 38px;
      background: #ec8575;
      border-radius: 3px;
      font-size: 14px;
      color: #fff;
      font-weight: bold;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>