<template>
  <div>
    <div class="banner">
      <img src="../../assets/images/product-banner_9.png" alt="" />
    </div>
    <div class="product-nav-wrap">
      <div class="product-nav">
        <a
          v-for="item in navList"
          :key="item.name"
          @click="navHandle(item.url)"
          >{{ item.name }}</a
        >
      </div>
    </div>
    <div class="program-wrap" id="program-wrap">
      <div
        class="program-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        方案概述
      </div>
      <div
        class="program-mes fadeOutUp"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
        style="transition-delay: 0.2s"
      >
        弹性电网综合解决方案，由八个部分组成，以此通过多元融合高弹性电网建设，在不改变电网物理形态的前提下，以改善电网辅助服务能力，为电网赋能，改变电网运行机制，提高电网安全抗扰能力，从而大幅提升社会综合能效水平
      </div>
      <div class="program-content">
        <div class="program-item" v-for="item in programList" :key="item.name">
          <div>
            <img
              :src="item.icon"
              :style="{ width: item.width + 'px' }"
              alt=""
            />
          </div>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isElementNotInViewport } from "../../utils/index.js";

export default {
  data() {
    return {
      navList: [
        {
          name: "方案概述",
          url: "program-wrap",
        },
      ],
      programList: [
        {
          icon: require("../../assets/images/powerGrid-icon_1.png"),
          width: 102,
          name: "灵活规划网架坚强",
          animate: "animate3",
          delay: 1.1,
        },
        {
          icon: require("../../assets/images/powerGrid-icon_2.png"),
          width: 106,
          name: "电网引导多能互联",
          animate: "animate4",
          delay: 1.1,
        },
        {
          icon: require("../../assets/images/powerGrid-icon_3.png"),
          width: 123,
          name: "设备挖潜运行高效",
          animate: "animate5",
          delay: 1.1,
        },
        {
          icon: require("../../assets/images/powerGrid-icon_4.png"),

          width: 100,
          name: "安全承载耐受抗扰",
          animate: "animate6",
          delay: 1.1,
        },
        {
          icon: require("../../assets/images/powerGrid-icon_5.png"),
          width: 115,
          name: "源网荷储弹性平衡",
          animate: "animate7",
          delay: 1.1,
        },
        {
          icon: require("../../assets/images/powerGrid-icon_6.png"),
          width: 87,
          name: "用户资源唤醒集聚",
          animate: "animate8",
          delay: 1.1,
        },
        {
          icon: require("../../assets/images/powerGrid-icon_7.png"),
          width: 92,
          name: "市场改革机制配套",
          animate: "animate9",
          delay: 1.1,
        },
        {
          icon: require("../../assets/images/powerGrid-icon_8.png"),
          width: 88,
          name: "科创引领数智赋能",
          animate: "animate10",
          delay: 1.1,
        },
      ],
      animateData: {
        animate: false,
        animate1: false,
      },
    };
  },
  mounted() {
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    navHandle(id) {
      let target = document.getElementById(id);
      target.scrollIntoView();
    },
    // 滚动事件
    scrollToTop() {
      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  img {
    width: 100%;
    display: block;
  }
}
.program-wrap {
  padding: 50px 0 43px;
  background: #fff;
  // position: relative;
  .program-title {
    color: #243159;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
  }
  .program-mes {
    font-size: 16px;
    line-height: 30px;
    color: #63697b;
    width: 995px;
    margin: 24px auto 0;
    text-align: center;
  }
  .program-content {
    width: 1180px;
    height: 518px;
    margin: 44px auto 0;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 0px 10px 8px rgba(90, 90, 90, 0.08);
    border-radius: 8px;
    padding: 73px 0 54px;
  }
  .program-item {
    width: 25%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    div {
      width: 123px;
      height: 123px;
      text-align: center;
      line-height: 123px;
      vertical-align: middle;
      margin-bottom: 23px;

      img {
        vertical-align: middle;
      }
    }
    span {
      color: #243159;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
</style>